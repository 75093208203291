import Icons from "../../../assets/icons";
import { Labels } from "../../../locales";

const {
  UserIcon,
  FlightManagementIcon,
  RouteManagementIcon,
  DashboardIcon,
  UserRoleManagementIcon,
  BookingManagementIcon,
  HistoricalLogsIcon,
  MenuSettingsIcon,
} = Icons;

let navigation = [];
const userRole = localStorage.getItem("role");
// alert(userRole);
if (userRole === 'company') {
  // alert('dsdsd');
  navigation = [
  {
    name: Labels.usersManagement,
    href: "/users-management",
    icon: (props) => <UserIcon {...props} />,
    current: false,
  },

  // {
  //   name: Labels.userRoleManagement,
  //   href: "/user-role-management",
  //   icon: (props) => <UserRoleManagementIcon {...props} />,
  //   current: false,
  // },
  {
    name: Labels.affiliationLink,
    href: "/affiliation-link",
    icon: (props) => <FlightManagementIcon {...props} />,
    current: false,
  },
  
  
  {
    name: Labels.dashboard,
    href: "/dashboard",
    icon: (props) => <DashboardIcon {...props} />,
    current: false,
  }
];
}
else if (userRole === 'ambassador') {
   navigation = [
    {
      name: Labels.usersManagement,
      href: "/users-management",
      icon: (props) => <UserIcon {...props} />,
      current: false,
    },
   
    {
      name: Labels.organizationsManagement,
      href: "/organizations-management",
      icon: (props) => <UserIcon {...props} />,
      current: false,
    },
    {
      name: Labels.flightManagement,
      href: "/flight-management",
      icon: (props) => <FlightManagementIcon {...props} />,
      current: false,
    },
    {
      name: Labels.affiliationLink,
      href: "/affiliation-link",
      icon: (props) => <FlightManagementIcon {...props} />,
      current: false,
    },
    
    // {
    //   name: Labels.routeManagement,
    //   href: "/route-management",
    //   icon: (props) => <RouteManagementIcon {...props} />,
    //   current: false,
    // },
    {
      name: Labels.dashboard,
      href: "/dashboard",
      icon: (props) => <DashboardIcon {...props} />,
      current: false,
    },
    // {
    //   name: Labels.userRoleManagement,
    //   href: "/user-role-management",
    //   icon: (props) => <UserRoleManagementIcon {...props} />,
    //   current: false,
    // },
    // {
    //   name: Labels.bookingManagement,
    //   href: "/booking-management",
    //   icon: (props) => <BookingManagementIcon {...props} />,
    //   current: false,
    // },
    {
      name: Labels.historicalLogs,
      href: "/historical-logs",
      icon: (props) => <HistoricalLogsIcon {...props} />,
      current: false,
    },
    {
      name: Labels.menuSettings,
      href: "/menu-settings",
      icon: (props) => <MenuSettingsIcon {...props} />,
      current: false,
    },
  ];
} else {
   navigation = [
    {
      name: Labels.usersManagement,
      href: "/users-management",
      icon: (props) => <UserIcon {...props} />,
      current: false,
    },
    
    {
      name: Labels.ambassadorsManagement,
      href: "/ambassadors-management",
      icon: (props) => <UserIcon {...props} />,
      current: false,
    },
    {
      name: Labels.organizationsManagement,
      href: "/organizations-management",
      icon: (props) => <UserIcon {...props} />,
      current: false,
    },
    {
      name: Labels.CountriesManagement,
      href: "/countries-management",
      icon: (props) => <FlightManagementIcon {...props} />,
      current: false,
    },
    {
      name: Labels.CitiesManagement,
      href: "/cities-management",
      icon: (props) => <FlightManagementIcon {...props} />,
      current: false,
    },
    // {
    //   name: Labels.flightManagement,
    //   href: "/flight-management",
    //   icon: (props) => <FlightManagementIcon {...props} />,
    //   current: false,
    // },
    {
      name: Labels.affiliationLink,
      href: "/affiliation-link",
      icon: (props) => <FlightManagementIcon {...props} />,
      current: false,
    },
    
    {
      name: Labels.routeManagement,
      href: "/route-management",
      icon: (props) => <RouteManagementIcon {...props} />,
      current: false,
    },
    {
      name: Labels.dashboard,
      href: "/dashboard",
      icon: (props) => <DashboardIcon {...props} />,
      current: false,
    },
    {
      name: Labels.userRoleManagement,
      href: "/user-role-management",
      icon: (props) => <UserRoleManagementIcon {...props} />,
      current: false,
    },
    {
      name: Labels.bookingManagement,
      href: "/booking-management",
      icon: (props) => <BookingManagementIcon {...props} />,
      current: false,
    },
    {
      name: Labels.historicalLogs,
      href: "/historical-logs",
      icon: (props) => <HistoricalLogsIcon {...props} />,
      current: false,
    },
    {
      name: Labels.menuSettings,
      href: "/menu-settings",
      icon: (props) => <MenuSettingsIcon {...props} />,
      current: false,
    },
  ];
}
export {navigation};
export const MAP_API_KEY = "AIzaSyCu9YGSvrE22iUL4Xhe3ISk-B0r8FTW9jI";
