import { GET_AIRPORTS, GET_EMPLOYEE } from "../../actionTypes";

const initialState = {
  employee: [],
  airports: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload,
      };
    case GET_AIRPORTS:
      return {
        ...state,
        airports: action.payload,
      };

    default:
      return state;
  }
};
