export const Labels = {
  userManagement: "user management",
  usersManagement: "users management",
  ambassadorManagement:"ambassador management",
  ambassadorsManagement:"ambassadors management",
  organizationManagement:"organization management",
  organizationsManagement:"organizations management",
  flightManagement: "flight management",
  routeManagement: "route management",
  dashboard: "dashboard",
  userRoleManagement: "user role management",
  affiliationLink: "Affiliation Link",
  CountryManagement: "country management",
  CountriesManagement: "countries management",
  addCountry:"Add Country",
  saveCountry:"save Country",
  editCountry:"edit Country",
  CityManagement: "city management",
  CitiesManagement: "cities management",
  addCity:"Add City",
  saveCity:"save City",
  editCity:"edit City",
  bookingManagement: "booking management",
  historicalLogs: "historical logs",
  menuSettings: "menu settings",
  addUser: "Add user",
  saveUser: "save user",
  editUser: "edit user",
  addOrganization: "Add organization",
  saveOrganization: "save organization",
  editOrganization: "edit organization",
  addAmbassador: "Add ambassador",
  saveAmbassador: "save ambassador",
  editAmbassador: "edit ambassador",
  loading: "loading ...",
  addAirport: "Add Airport",
  editAirport: "Edit Airport",
  saveAirport: "Save Airport",
};
