import React from "react";
import Icons from "../../../assets/icons";
const { FlyPoolLogo_2 } = Icons;
const LazyLoaderSpinner1 = () => {
  return (
    <div
      role="status"
      className="bg-primary h-screen flex items-center justify-center z-50 fixed w-full top-0"
    >
      <div className="flex items-center flex-col gap-5">
        <img
          className="w-52"
          src={FlyPoolLogo_2.default}
          draggable={false}
          alt="FlyPoolLogo"
        />

        <div className="w-32 h-32 border-4  border-t-info border-r-4 border-l-3 border-white rounded-full animate-spin shadow"></div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default LazyLoaderSpinner1;
