import { GET_ORGANIZATIONS } from "../../actionTypes";

const initialState = {
  organizations: [],
};

export default (state = initialState, action) => {
  ////console.log('reducer')
  switch (action.type) {
    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    default:
      return state;
  }
};
