import { combineReducers } from "redux";
import userManagement from "./userManagement";
import userRoleManagement from "./userRoleManagement";
import routeManagement from "./routeManagement";
import flightManagement from "./flightManagement";
import bookingManagement from "./bookingManagement";
import dashboard from "./dashboard";
import historyLogs from "./historyLogs";
import ambassadorManagement from "./ambassadorManagement";
import organizationManagement from "./organizationManagement";
import countryManagement from "./countryManagement";
import cityManagement from "./cityManagement";

export default combineReducers({
  userManagement,
  userRoleManagement,
  routeManagement,
  flightManagement,
  bookingManagement,
  dashboard,
  historyLogs,
  ambassadorManagement,
  organizationManagement,
  countryManagement,
  cityManagement
});
