import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LazyLoaderSpinner1 from "../components/lazyLoaderSpinners/spinner1";
import LazyLoaderSpinner2 from "../components/lazyLoaderSpinners/spinner2";

import AuthHeader from "../layout/AuthHeader";
const delayTime = 1000;
const AppRoutes = () => {
  const login = localStorage.getItem("token") || null;
  const routes = [
    ...(login
      ? [
          {
            id: "home",
            path: "/",
            component: lazy(() => {
              return new Promise((resolve) => {
                setTimeout(
                  () => resolve(import("../layout/DashboardSidebar")),
                  delayTime
                );
              });
            }),

            nestedPaths: [
              {
                id: "users-management",
                path: "/users-management",
                component: lazy(() => import("../pages/userManagement/users")),
                nestedPaths: [],
              },
              {
                id: "users-management/add-user",
                path: "/users-management/add-user",
                component: lazy(() =>
                  import("../pages/userManagement/addAndUpdateUser")
                ),

                nestedPaths: [],
              },
              {
                id: "users-management/import-user",
                path: "/users-management/import-user",
                component: lazy(() =>
                  import("../pages/userManagement/importUser")
                ),

                nestedPaths: [],
              },
              {
                id: "users-management/bulk-add-user",
                path: "/users-management/bulk-add-user",
                component: lazy(() =>
                  import("../pages/userManagement/bulkAddUser")
                ),

                nestedPaths: [],
              },
              {
                id: "ambassadors-management",
                path: "/ambassadors-management",
                component: lazy(() => import("../pages/ambassadorManagement/ambassadors")),
                nestedPaths: [],
              },
              {
                id: "ambassadors-management/add-ambassador/",
                path: "/ambassadors-management/add-ambassador",
                component: lazy(() =>
                  import("../pages/ambassadorManagement/addAndUpdateAmbassador")
                ),

                nestedPaths: [],
              },
              
              {
                id: "ambassadors-management/add-bulk-ambassador",
                path: "/ambassadors-management/add-bulk-ambassador",
                component: lazy(() =>
                  import("../pages/ambassadorManagement/addBulkAmbassador")
                ),

                nestedPaths: [],
              },
              {
                id: "ambassadors-management/import-ambassador",
                path: "/ambassadors-management/import-ambassador",
                component: lazy(() =>
                  import("../pages/ambassadorManagement/importAmbassador")
                ),

                nestedPaths: [],
              },
              {
                id: "organizations-management",
                path: "/organizations-management",
                component: lazy(() => import("../pages/organizationManagement/organizations")),
                nestedPaths: [],
              },
              {
                id: "organizations-management/add-organization",
                path: "/organizations-management/add-organization",
                component: lazy(() =>
                  import("../pages/organizationManagement/addAndUpdateOrganization")
                ),

                nestedPaths: [],
              },
              {
                id: "organizations-management/import-organization",
                path: "/organizations-management/import-organization",
                component: lazy(() =>
                  import("../pages/organizationManagement/importOrganization")
                ),

                nestedPaths: [],
              },
              {
                id: "organizations-management/add-bulk-organization",
                path: "/organizations-management/add-bulk-organization",
                component: lazy(() =>
                  import("../pages/organizationManagement/addBulkOrganization")
                ),

                nestedPaths: [],
              },
              {
                id: "flight-management",
                path: "/flight-management",
                component: lazy(() =>
                  import("../pages/flightManagement/flights")
                ),
                nestedPaths: [],
              },
              {
                id: "flight-management/add-flight",
                path: "/flight-management/add-flight",
                component: lazy(() =>
                  import("../pages/flightManagement/addAndUpdateFlight")
                ),
                nestedPaths: [],
              },
              {
                id: "affiliation-link",
                path: "/affiliation-link",
                component: lazy(() =>
                  import("../pages/affiliationLink/link")
                ),
                nestedPaths: [],
              },
              
              {
                id: "affiliation-link/add-user/:companyId?",
                path: "/affiliation-link/add-user/:companyId?",
                component: lazy(() =>
                import("../pages/affiliationLink/addUser")
                ),
    
                nestedPaths: [],
                
              },
              {
                id: "affiliation-link-ambassador/:country?/:airportdata?",
                path: "/affiliation-link-ambassador/:country?/:airportdata",
                component: lazy(() =>
                import("../pages/affiliationLink/addAmbassador")
                ),
    
                nestedPaths: [],
                
              },
              {
                id: "affiliation-link-organization/:ambassadorid?/:airportdata?",
                path: "/affiliation-link-organization/:ambassadorid?/:airportdata?",
                component: lazy(() =>
                import("../pages/affiliationLink/addOrganization")
                ),
    
                nestedPaths: [],
                
              },
              {
                id: "affiliation-link-user/:country?/:airportdata?/:organizationdata?",
                path: "/affiliation-link-user/:country?/:airportdata?/:organizationdata?",
                component: lazy(() =>
                import("../pages/affiliationLink/addUser")
                ),
    
                nestedPaths: [],
                
              },
              {
                id: "countries-management",
                path: "/countries-management",
                component: lazy(() =>
                  import("../pages/countryManagement/countries")
                ),
                nestedPaths: [],
              },
              {
                id: "countries-management/add-country",
                path: "/countries-management/add-country",
                component: lazy(() =>
                  import("../pages/countryManagement/addAndUpdateCountry")
                ),
                nestedPaths: [],
              },
              {
                id: "countries-management/bulk-add-country",
                path: "/countries-management/bulk-add-country",
                component: lazy(() =>
                  import("../pages/countryManagement/addBulkCountry")
                ),

                nestedPaths: [],
              },
              {
                id: "countries-management/import-country",
                path: "/countries-management/import-country",
                component: lazy(() =>
                  import("../pages/countryManagement/importCountry")
                ),

                nestedPaths: [],
              },
              
              
              
              {
                id: "route-management",
                path: "/route-management",
                component: lazy(() => import("../pages/routeManagement")),
                nestedPaths: [],
              },
              {
                id: "route-management/add-route",
                path: "/route-management/add-route",
                component: lazy(() =>
                  import("../pages/routeManagement/components/AddNewRoutes")
                ),
                nestedPaths: [],
              },
              {
                id: "cities-management",
                path: "/cities-management",
                component: lazy(() =>
                  import("../pages/cityManagement/cities")
                ),
                nestedPaths: [],
              },
              {
                id: "cities-management/add-city",
                path: "/cities-management/add-city",
                component: lazy(() =>
                  import("../pages/cityManagement/addAndUpdateCity")
                ),
                nestedPaths: [],
              },
              {
                id: "cities-management/bulk-add-city",
                path: "/cities-management/bulk-add-city",
                component: lazy(() =>
                  import("../pages/cityManagement/addBulkCity")
                ),

                nestedPaths: [],
              },
              {
                id: "cities-management/import-city",
                path: "/cities-management/import-city",
                component: lazy(() =>
                  import("../pages/cityManagement/importCity")
                ),

                nestedPaths: [],
              },

              {
                id: "dashboard",
                path: "/dashboard",
                component: lazy(() => import("../pages/dashboard")),
                nestedPaths: [],
              },
              {
                id: "user-role-management",
                path: "/user-role-management",
                component: lazy(() =>
                  import("../pages/userRoleManagement/roles")
                ),
                nestedPaths: [],
              },
              {
                id: "user-role-management-add-airport",
                path: "/user-role-management/add-airport",
                component: lazy(() =>
                  import(
                    "../pages/userRoleManagement/addAndUpdateCompanyAirport"
                  )
                ),
                nestedPaths: [],
              },
              {
                id: "booking-management",
                path: "/booking-management",
                component: lazy(() =>
                  import("../pages/bookingManagement/bookings")
                ),
                nestedPaths: [],
              },
              {
                id: "booking-management/trip-details",
                path: "/booking-management/trip-details",
                component: lazy(() =>
                  import("../pages/bookingManagement/bookings/tripDetails")
                ),
                nestedPaths: [],
              },

              {
                id: "historical-logs",
                path: "/historical-logs",
                component: lazy(() => import("../pages/historicalLogs/logs")),
                nestedPaths: [],
              },
              {
                id: "menu-settings",
                path: "/menu-settings",
                component: lazy(() => import("../pages/historicalLogs/logs")),
                nestedPaths: [],
              },

              {
                id: "users-management/add-user",
                path: "/users-management/add-user",
                component: lazy(() =>
                  import("../pages/userManagement/addAndUpdateUser")
                ),

                nestedPaths: [],
              },
            ],
          },
        ]
      : [
          {
            id: "login",
            path: "/",
            component: lazy(() => {
              return new Promise((resolve) => {
                setTimeout(() => resolve(import("../pages/login")), delayTime);
              });
            }),

            nestedPaths: [],
            auth: true,
          },
          
          {
            id: "ambassadorLogin",
            path: "/ambassadorLogin",
            component: lazy(() => {
              return new Promise((resolve) => {
                setTimeout(() => resolve(import("../pages/ambassadorlogin")), delayTime);
              });
            }),

            nestedPaths: [],
            auth: true,
          },
          {
            id: "superadminlogin",
            path: "/superadminlogin",
            component: lazy(() => {
              return new Promise((resolve) => {
                setTimeout(() => resolve(import("../pages/admin")), delayTime);
              });
            }),

            nestedPaths: [],
            auth: true,
          },
          
          {
            id: "signup",
            path: "/sign-up",
            component: lazy(() => {
              return new Promise((resolve) => {
                setTimeout(() => resolve(import("../pages/signUp")), delayTime);
              });
            }),

            nestedPaths: [],
            auth: true,
          },
          {
            id: "affiliation-link/add-user/:companyId?",
            path: "/affiliation-link/add-user/:companyId?",
            component: lazy(() =>
            import("../pages/affiliationLink/addUser")
            ),

            nestedPaths: [],
            
          },
          {
            id: "affiliation-link-ambassador/:country?/:airportdata?",
            path: "/affiliation-link-ambassador/:country?/:airportdata?",
            component: lazy(() =>
            import("../pages/affiliationLink/addAmbassador")
            ),

            nestedPaths: [],
            
          },
          {
            id: "affiliation-link-user/:country?/:airportdata?/:organizationdata?",
            path: "/affiliation-link-user/:country?/:airportdata?/:organizationdata?",
            component: lazy(() =>
            import("../pages/affiliationLink/addUser")
            ),

            nestedPaths: [],
            
          },
          {
            id: "affiliation-link-organization/:ambassadorid?/:airportdata?",
            path: "/affiliation-link-organization/:ambassadorid?/:airportdata?",
            component: lazy(() =>
            import("../pages/affiliationLink/addOrganization")
            ),

            nestedPaths: [],
            
          },
          
        ]),
  ];

  return (
    <>
      <Routes>
        {routes?.map((item) => (
          <Route
            key={item?.id}
            path={item?.path}
            element={
              <>
                {item?.auth && <AuthHeader />}
                <Suspense fallback={<LazyLoaderSpinner1 />}>
                  <item.component />
                </Suspense>
              </>
            }
          >
            {item?.nestedPaths?.length &&
              item?.nestedPaths?.map((subItem) => (
                <Route
                  key={subItem?.id}
                  path={subItem?.path}
                  element={
                    <Suspense fallback={<LazyLoaderSpinner2 />}>
                      <subItem.component />
                    </Suspense>
                  }
                />
              ))}
          </Route>
        ))}
      </Routes>
    </>
  );
};

export default AppRoutes;
