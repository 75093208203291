import React from "react";

const Button = ({
  children,
  disabled = false,
  loading = false,
  variant = "primary",
  size = "md",
  outline = false,
  fullWidth = false,
  pill = false,
  onClick = () => {},
  extraClasses,
  type = "button",
  ...rest
}) => {
  // Define classes based on props
  const baseClasses = "rounded-md focus:outline-none uppercase";
  const variantClasses = {
    primary: `${
      !outline ? "bg-primary text-white" : "border border-primary text-primary"
    } `,
    secondary: `${
      !outline
        ? "bg-secondary text-white"
        : "border border-secondary text-secondary"
    }`,
    danger: `bg-danger text-white`,
    info: `${!outline ? "bg-info text-white" : "border border-info text-info"}`,
    // Add more variants
  };
  const sizeClasses = {
    sm: "text-sm px-4 py-2",
    md: "text-base px-6 py-2",
    lg: "text-lg px-8 py-3",
    // Add more sizes
  };
  const outlineClasses = outline ? "border border-gray-300" : "";
  const fullWidthClasses = fullWidth ? "w-full" : "";
  const pillClasses = pill ? "rounded-full" : "";

  const loadingClasses = "opacity-50 cursor-not-allowed";

  // Combine classes
  const buttonClasses = `
  ${extraClasses} 
    ${baseClasses}
    ${variantClasses[variant]}
    ${sizeClasses[size]}
    ${outlineClasses}
    ${fullWidthClasses}
    ${pillClasses}
    ${loading ? loadingClasses : ""} 
  `;

  return (
    <button
      type={type}
      className={buttonClasses}
      disabled={disabled || loading}
      {...rest}
      onClick={onClick}
    >
      {loading ? "Loading..." : children}
    </button>
  );
};

export default Button;
