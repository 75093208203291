import {
  GET_DASHBOARD_TRIP_DATA,
  GET_DASHBOARD_ANALYTICS,
} from ".././../actionTypes/index";

const initialState = {
  trips: [],
  analytics: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_TRIP_DATA:
      return {
        ...state,
        trips: action.payload,
      };
    case GET_DASHBOARD_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
      };
    default:
      return state;
  }
};
