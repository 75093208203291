import { GET_BOOKINGS } from "../../actionTypes";

const initialState = {
  bookings: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      };

    default:
      return state;
  }
};
