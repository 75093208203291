export const GET_USERS = "GET_USERS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const GET_AMBASSADORS = "GET_AMBASSADORS";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_AIRPORTS = "GET_AIRPORTS";
export const GET_ROUTES = "GRT_ROUTES";
export const GET_FLIGHTS = "GET_FLIGHTS";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_DASHBOARD_TRIP_DATA = "GET_DASHBOARD_TRIP_DATA";
export const GET_DASHBOARD_ANALYTICS = "GET_DASHBOARD_ANALYTICS";
export const GET_HISTORY_LOGS = "GET_HISTORY_LOGS";
