import { GET_HISTORY_LOGS } from "../../actionTypes";

const initialState = {
  historyLogs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_LOGS:
      return {
        ...state,
        historyLogs: action.payload,
      };
    default:
      return state;
  }
};
